<template>
	<div class="hdTicket">
		<div class="page-header flex">
			<span style="font-size: 16px;">优惠券管理</span>
			<div>
				<div class="screen-btn ant-menu-item-selected" @click="_makeTodo('add')">
					<a-icon type="plus-circle" />
					<span>新增</span>
				</div>
			</div>
		</div>
		<div class="page-main">
			<div class="screen-list">
				<a-form-model layout="inline" :model="souForm">
					<a-form-model-item label="活动名称">
						<a-input placeholder="请输入关键字" v-model="souForm.name" allowClear />
					</a-form-model-item>
					<a-form-model-item label="部门">
						<a-tree-select v-model="souForm.department" :tree-data="departmentList" placeholder="请选择"
							allowClear tree-checkable> </a-tree-select>
					</a-form-model-item>
					<a-form-model-item label="平台">
						<a-select placeholder="请选择" v-model="souForm.type" allowClear>
							<a-select-option value="">全部</a-select-option>
							<a-select-option value="内部">内部</a-select-option>
							<a-select-option value="京东">京东</a-select-option>
							<a-select-option value="淘宝">淘宝</a-select-option>
							<a-select-option value="天猫">天猫</a-select-option>
							<a-select-option value="系统代金券">系统代金券</a-select-option>
						</a-select>
					</a-form-model-item>
					<a-form-model-item label="活动时间">
						<a-range-picker style="width: 200px" format="YYYY-MM-DD" :placeholder="['开始时间', '结束时间']"
							@change="ChangeSearchTime" />
					</a-form-model-item>
					<a-form-model-item label="状态">
						<a-select placeholder="请选择" v-model="souForm.status" allowClear>
							<a-select-option value="">全部</a-select-option>
							<a-select-option value="0">正常</a-select-option>
							<a-select-option value="1">过期已删除</a-select-option>
						</a-select>
					</a-form-model-item>
				</a-form-model>
				<div class="form-btn">
					<a-button @click="_resetBtn">重置条件</a-button>
					<a-button type="primary" @click="_searchBtn">开始查询</a-button>
				</div>
			</div>
			<div class="todo-content">
				<a-table :columns="columns" :dataSource="infoData" :pagination="false" bordered v-if="infoData">
					<div slot="StatusText" slot-scope="text, row">
						<a-popconfirm title="您确定更改其状态?" @confirm="_setTableRow(row,text)" okText="确定" cancelText="取消">
							<span class="status-icon"
								:style="text=='禁用'?'background: #E81B1C':'background: #4199F3'"></span> {{text}}
						</a-popconfirm>
					</div>
					<div slot="Operation" slot-scope="text, row">
						<a-icon type="form" @click="_makeTodo(row)" class="icon-btn" />
						<a-popconfirm title="您确定要恢复吗?" @confirm="_recoTableRow(row)" okText="确定" cancelText="取消">
							<a-button type="link" v-if="row.DelBoolean==1">恢复</a-button>
						</a-popconfirm>
						<a-popconfirm title="您确定要删除吗?" @confirm="_delTableRow(row)" okText="确定" cancelText="取消">
							<a-icon type="delete" class="icon-btn" v-if="row.DelBoolean==0" />
						</a-popconfirm>
					</div>
				</a-table>

				<div style="margin: 20px 0; text-align: right;">
					<a-pagination showQuickJumper :defaultCurrent="1" :total="pageTotal" @change="_PageChange" />
				</div>
			</div>

			<a-drawer :title="isEdit ? '优惠卷信息' : '添加优惠卷'" width="1000" :closable="false" :visible="FinanceVisible"
				@close="financeClose">
			 
					<addFinance ref="addFinance" v-if="FinanceVisible" :formData="formData" @visible="financeClose">
					</addFinance>
			  <div class="drawer-bottom">
				<a-button style="width: 120px; text-align: center; margin-right: 20px;" @click="onMakeClose">取消
				</a-button>
				<a-button type="primary" style="width: 120px; text-align: center;" @click="_saveMakeTodo">提交</a-button>
			 </div>
			</a-drawer>
		</div>
	</div>
</template>

<script>
	import addFinance from 'pages/ClassFee/Finance/addFinance'

	const columns = [{
			title: '活动名称',
			dataIndex: 'Name',
			align: 'center'
		},
		{
			title: '状态',
			dataIndex: 'StatusText',
			width: 110,
			align: 'center',
			scopedSlots: {
				customRender: 'StatusText'
			},
		},
		{
			title: '平台',
			dataIndex: 'Type',
			align: 'center'
		},
		{
			title: '优惠券类型',
			dataIndex: 'CouponTypeText',
			align: 'center'
		},
		{
			title: '购买方式',
			dataIndex: 'BuyType',
			align: 'center'
		},
		{
			title: '开始时间',
			dataIndex: 'StartDate',
			align: 'center'
		},
		{
			title: '结束时间',
			dataIndex: 'EndDate',
			align: 'center'
		},
		{
			title: '活动范围',
			dataIndex: 'SchoolIdText',
			align: 'center',
			width: '400px'
		},
		{
			title: '实际金额',
			dataIndex: 'ActualMoney',
			align: 'center'
		},
		{
			title: '抵扣金额',
			dataIndex: 'DeductionMoney',
			align: 'center'
		},
		{
			title: '负责人',
			dataIndex: 'PrincipalName',
			align: 'center'
		},
		{
			title: '操作',
			dataIndex: 'Operation',
			align: 'center',
			scopedSlots: {
				customRender: 'Operation'
			}
		}
	]
	export default {
		name: 'hdTicket',
		components: {
			addFinance
		},
		data() {
			return {
				columns: columns,
				pageTotal: 0,
				page: 1,
				souForm: {
					name: undefined, //关键字
					type: undefined, // 类别
					status: undefined, // 状态
					department: [], //部门
					StartDate: '',
					EndDate: '',
				},
				FinanceVisible: false,
				infoData: [],
				isEdit: false,
				formData: {},
				departmentList: []
			}
		},
		created() {
			this.getDepartmentList()
			this._info()
		},
		methods: {
			ChangeSearchTime(time, timeString) {
				this.souForm.StartDate = timeString[0]
				this.souForm.EndDate = timeString[1]
			},
			onMakeClose() {
				this.FinanceVisible = false
			},
			// 显示表单
			_makeTodo(row) {
				if (row !== 'add') {
					this.isEdit = true
					this.formData = {
						...row
					}
				} else {
					this.isEdit = false
					this.formData = {}
				}
				this.FinanceVisible = true
			},
			// 检索
			_searchBtn() {
				this.page = 1
				this._info()
			},
			// 重置
			_resetBtn() {
				this.souForm.name = undefined
				this.souForm.type = undefined
				this.souForm.status = undefined
				this.souForm.EndDate = ''
				this.souForm.StartDate = ''
				this.souForm.department = []
			},
			_setTableRow(index, text) {
				var Status = 0
				if (text == "禁用") {
					Status = 1
				}
				this.$axios.post(
					80813004, {
						Id: index.Id,
						Status: Status
					},
					res => {
						if (res.data.code == 1) {
							this.$message.success(res.data.msg)
						} else {
							this.$message.success(res.data.msg)
						}
						this._info()
					}
				)
			},
			_recoTableRow(index) {
				this.$axios.post(
					80813007, {
						Id: index.Id
					},
					res => {
						if (res.data.code == 1) {
							this.$message.success(res.data.msg)
						} else {
							this.$message.success(res.data.msg)
						}
						this._info()
					}
				)
			},
			// 删除
			_delTableRow(index) {
				this.$axios.post(
					80813003, {
						Id: index.Id
					},
					res => {
						if (res.data.code == 1) {
							this.$message.success('删除成功')
						} else {
							this.$message.success(res.data.msg)
						}
						this._info()
					}
				)
			},
			// 分页事件
			_PageChange(page) {
				this.page = page
				this._info()
			},
			// 关闭表单
			financeClose(key) {
				if (key === 'actClose') {
					this.page = 1
					this._info()
				}
				this.FinanceVisible = false
			},
			// 获取表格内容
			_info() {
				this.$message.loading('加载中...')
				let department = this.souForm.department.join()
				let data = {
					Type: this.souForm.type,
					EndDate: this.souForm.EndDate,
					StartDate: this.souForm.StartDate,
					FinName: this.souForm.name,
					DelStatus: this.souForm.status,
					SchoolId: department,
					Page: this.page,
					PageSize: 10
				}
				this.$axios.get(80813001, data, res => {
					if (res.data.code == 1) {
						this.infoData = res.data.data
						this.pageTotal = res.data.count
						this.page = this.page
					} else {
						this.pageTotal = 1
						this.infoData = []
					}
					this.$message.destroy()
				})
			},
			// 获取部门
			getDepartmentList() {
				this.$axios.get(5836120, {}, res => {
					if (res.data.code == 1) {
						this.departmentList = res.data.data.schoollist
					}
				})
			},
			async _saveMakeTodo() {
				let result1
				try {
					result1 = await this.$refs.addFinance.onSubmit()
				} catch (error) {
					// console.error(error)
				}

				if (result1) {
					let form = {
						...result1
					}
					for (const key in form) {
						if (typeof form[key] === 'boolean') {
							form[key] = +form[key]
						}
						if (typeof form[key] === 'object') {
							if (key === 'SchoolId' || key === 'PrincipalName' || key === 'PrincipalId') {
								form[key] = form[key].join()
								if (form[key] === 'undefined') {
									form[key] = ''
								}
							}
						}
					}
					// 如果是编辑
					if (this.formData.Id) {
						form.Id = this.formData.Id
						form.CreateUserId = JSON.parse(sessionStorage.getItem('userInfo')).uid
					}
					this.$axios.post(80813002, {
						...form
					}, res => {
						if (res.data.code == 1) {
							this.$message.success(res.data.msg)
							this.$emit('visible', 'actClose')
							this._info()
						} else {
							this.$message.error(res.data.msg)
						}

					})
				}
			}
		}
	}
</script>

<style scoped lang="less">
	.hdTicket {
		width: 100%;
		background: #fff;
		box-sizing: border-box;
		font-size: 14px;

		.page-header {
			height: 55px;
			line-height: 55px;
			padding: 10px 20px;
			border-bottom: 1px solid #e1e2e3;

			.screen-btn {
				width: 100px;
				display: inline-block;
				font-size: 12px;
				margin-left: 20px;
				border-width: 1px;
				border-style: solid;
				border-radius: 20px;
				padding: 9px 0;
				line-height: 1;
				text-align: center;
				background: #fff !important;
				cursor: pointer;

				i {
					margin-right: 4px;
				}
			}
		}

		.page-main {
			padding: 20px;

			.screen-list {
				margin: 0 0 15px;
				display: flex;
				justify-content: space-between;
				align-items: center;

				:deep(.ant-select-selection--single),
      :deep(.ant-form-item-control) {
        min-width: 150px;
      }

				.form-btn {
					button+button {
						margin-left: 10px;
					}
				}
			}
		}

		.icon-btn {
			cursor: pointer;
			margin-left: 10px;
			font-size: 18px;
		}

		.status-icon {
			display: inline-block;
			vertical-align: middle;
			width: 8px;
			height: 8px;
			border-radius: 50%;
		}
		  .drawer-bottom {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            border-top: 1px solid #e9e9e9;
            padding: 10px 16px;
            background: #fff;
            }
	}
</style>
