<template>
	<div class="rentQi">
		<a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="{ span: 4 }" :wrapper-col="{ span: 11 }">
			<a-form-model-item label="优惠卷名称" prop="Name">
				<a-input v-model="form.Name" allowClear />
			</a-form-model-item>
			<a-form-model-item label="优惠券类型" prop="CouponType">
				<a-select show-search allowClear placeholder="请选择" v-model="form.CouponType">
					<a-select-option v-for="item in HDTicketTypeList" :key="item.value" :value="item.value">
						{{ item.label }}
					</a-select-option>
				</a-select>
			</a-form-model-item>

			<a-form-model-item label="平台">
				<a-select show-search allowClear placeholder="请选择" v-model="form.Type">
					<a-select-option v-for="item in HDTicketCategoryTypeList" :key="item.value" :value="item.value">
						{{ item.label }}
					</a-select-option>
				</a-select>
			</a-form-model-item>
			<a-form-model-item label="活动范围	">
				<a-tree-select v-model="form.SchoolId" :tree-data="treeData" placeholder="请选择" allowClear
					tree-checkable> </a-tree-select>
			</a-form-model-item>
			<a-form-model-item label="负责人">
				<div class="row" style="position:relative">
					<a-input style="width: 420px;" placeholder="请输入" v-model="form.PrincipalName" />
					<div style="position:absolute;top:0;left:0;opacity: 0;z-index:100;width:100%;height: 100%">
						<person v-bind="personObj" @getData="_getPerson" placeholder="请输入"></person>
					</div>
				</div>
			</a-form-model-item>

			<a-form-model-item label="购买渠道">
				<a-input v-model="form.BuyType" allowClear placeholder="格式：家长自购/企业垫付" />
			</a-form-model-item>
			<a-form-model-item label="有效期">
				<a-range-picker v-model="form.Effect" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"
					:placeholder="['开始时间', '结束时间']" allowClear @change="dateChange" />
			</a-form-model-item>

			<a-form-model-item label="实际支付金额">
				<a-input-number v-model="form.ActualMoney" allowClear style="width:390px;"  />&nbsp;&nbsp;元
			</a-form-model-item>
			<a-form-model-item label="抵扣金额">
				<a-input-number v-model="form.DeductionMoney" allowClear style="width:390px;"  />&nbsp;&nbsp;元
			</a-form-model-item>


		</a-form-model>
	</div>
</template>

<script>
	import person from 'components/SelectPersonnel/SelectPersonnel' //选人
	export default {
		name: 'rentQi',
		components: {
			person
		},
		data() {
			return {
				personObj: {
					rank: 1, //职级 0为不选，1为选
					nums: 10, //选的数量
					department: 0, //0不选部门，1为都选（人 部门都选），2为只选部门，选人
					contacts: '', //常用联系人 不要常用联系人传空''
					selectArr: [] //要传入的数据
				},
				form: {
					Name: '',
					CouponType: 0,
					Type: '',
					SchoolId: undefined,
					BuyType: '',
					PrincipalId: '',
					PrincipalName: [],
					Effect: '',
					ActualMoney: 0,
					DeductionMoney: 0
				},
				isShowDepartment: false,
				rules: {
					Name: [{
						required: true,
						message: '请输入名称',
						trigger: 'blur'
					}]


				},
				userinfo: JSON.parse(sessionStorage.getItem('userInfo')),
				treeData: [],
				HDTicketCategoryTypeList: [],
				HDTicketTypeList: [],
				PersonId: [],
				PersonName: []
			}
		},
		props: {
			formData: {
				type: Object,
				default: () => {
					return {}
				}
			}
		},
		created() {

			// 如果是编辑数据，修正表单数据类型
			if (this.formData.Id) {

				for (const key in this.form) {
					if (typeof this.form[key] === 'boolean') {
						this.form[key] = !!this.formData[key]
					} else if (key === 'SchoolId') {
						this.form[key] = this.formData[key] ? this.formData[key].split(',') : []
						if (this.formData[key] != "") {
							this.isShowDepartment = true;
						}
					} else if (key === 'Effect') {
						this.form.Effect = [this.formData.StartDate, this.formData.EndDate]
					} else {
						this.form[key] = this.formData[key]
					}
				}
			}
			console.log(this.form)
			//获取优惠券平台类型
			this.$axios.get(80813006, {
				label: 'FeeClass'
			}, res => {
				if (res.data.code == 1) {
					this.HDTicketCategoryTypeList = res.data.data

				}
			})
			//获取优惠券类型
			this.$axios.get(80813005, {
				label: 'FeeClass'
			}, res => {
				if (res.data.code == 1) {
					this.HDTicketTypeList = res.data.data

				}
			})

			this.$axios.get(80811009, {
				label: 'FeeClass'
			}, res => {
				if (res.data.code == 1) {
					this.ConcessionIsMainList = res.data.data
				}
			})

			// 获取部门
			this.$axios.get(
				5836120, {
					userId: this.userinfo.uid
				},
				res => {
					if (res.data.code == 1) {
						this.treeData = res.data.data.schoollist
						// this.StatusList = res.data.data.statuslist
					}
				}
			)

			this.ClassTypeList()
		},
		methods: {
			_getPerson(Person) {
				this.PersonName = []
				this.PersonId = []
				for (let i = 0; i < Person.length; i++) {
					// self.value.push(self.selectData[i].title);
					this.PersonName.push(Person[i].title)
					this.PersonId.push(Person[i].id)
				}
				this.form.PrincipalName = this.PersonName.join()
				this.form.PrincipalId = this.PersonId.join()
			},
			// 适用范围选择事件
			EfectAreaChange(val) {
				let rules = {
					...this.rules
				}
				this.isShowDepartment = val === 2
				if (val !== 2) {
					this.form.EffectSchoolId = ''
					if (rules.EffectSchoolId) {
						this.$refs.ruleForm.clearValidate('EffectSchoolId')
					}

					delete rules.EffectSchoolId
				} else {
					rules.EffectSchoolId = [{
						required: true,
						message: '请选择部门',
						trigger: 'change'
					}]
				}
				this.rules = {
					...rules
				}
			},
			ClassTypeList() {
				//获取课程类别
				this.$axios.get(80811004, {
					label: 'FeeClass'
				}, res => {
					if (res.data.code == 1) {
						this.ClassType = [...res.data.data]
					}
				})

			},
			// 日期控件值变化
			dateChange(val) {
				if (val[0]) {
					this.form.StartDate = val[0]
					this.form.EndDate = val[1]
				} else {
					this.form.StartDate = ''
					this.form.EndDate = ''
				}
			},
			handleChange(key, val) {
				if (this.form[key].some(item => item === 'all')) {
					this.form[key] = ['all']
					this.$refs[key].blur()
				}
			},
			onSubmit() {
				return new Promise((resolve, reject) => {
					this.$refs.ruleForm.validate(valid => {
						if (valid) {
							resolve(this.form)
						} else {
							reject(false)
						}
					})
				})
			}
		}
	}
</script>

<style scoped lang="less">
	.rentQi {
		background: #ffffff;
		margin-top: 15px;
		min-height: 800px;
		padding: 15px;
	}
</style>
