import { render, staticRenderFns } from "./addFinance.vue?vue&type=template&id=665db2db&scoped=true&"
import script from "./addFinance.vue?vue&type=script&lang=js&"
export * from "./addFinance.vue?vue&type=script&lang=js&"
import style0 from "./addFinance.vue?vue&type=style&index=0&id=665db2db&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "665db2db",
  null
  
)

export default component.exports